import { integrationSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  PAY_SCHEDULE_FORM,
  PAYROLL,
  PAYROLL_HUB,
  PAYROLL_SETUP,
  PAYROLLS,
  PAYSLIPS,
  SALARY,
  SALARY_COMPONENTS,
  SALARY_INFO,
  SALARY_STRUCTURE,
  SETTINGS_SALARY,
} from "./path-name.route";

export const payrollRoutes: IRouteList[] = [
  // ------------for dashboard payroll-----------
  {
    path: PAYROLL + "/",
    component: lazy(() => import("@pages/Payroll/Salary/Salary.tsx")),
    // meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: SALARY,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/EmploySalaries")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
      {
        path: PAYSLIPS,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/Payslips/EmployPayslips")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
      {
        path: SALARY_INFO,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaryInfo")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },
  {
    path: PAYROLL + "/" + SALARY + "/details",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/Components/EmploySalaryDetails")),
    // meta: integrationSettingsAcl.apis.parent,
  },
  {
    path: PAYROLL + "/" + SALARY + "/assign",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/Components/SalaryAssign")),
    // meta: integrationSettingsAcl.apis.parent,
  },

  {
    path: PAYROLL + "/" + PAYSLIPS + "/details",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/Payslips/Componnents/EmployPayslipDetails")),
    // meta: integrationSettingsAcl.apis.parent,
  },

  // dashboard payroll hub
  {
    path: PAYROLL_HUB + "/",
    component: lazy(() => import("@pages/Payroll/PayrollHub/PayrollHub")),
    // meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: PAYROLLS,
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/Payrolls")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
      {
        path: PAYSLIPS,
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payslips/Payslips")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },
  {
    path: PAYROLL_HUB + "/" + PAYSLIPS + "/details",
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payslips/Components/PayslipDetails")),
    // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
  },

  {
    path: `${PAYROLL_HUB}/${PAYROLLS}/details/:payrollId`,
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/PayrollDetailsTab")),
    children: [
      {
        path: "salary-disbursement", // Child route 1
        component: lazy(
          () => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/Screen/SalaryDisbursement")
        ),
      },
      {
        path: "settings", // Child route 2
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/components/AttPolicySettings")),
      },
    ],
  },

  {
    path: `${PAYROLL_HUB}/${PAYROLLS}/details/:payrollId/salary-disbursement/details/:disburseGroupId`,
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/Screen/DisburseGroupDetails")),
  },

  // ----------------for settings payroll-----------
  {
    path: SETTINGS_SALARY,
    component: lazy(() => import("@pages/Settings/PayrollSettings/SalarySettings/Salary")),
    meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: SALARY_COMPONENTS,
        component: lazy(
          () => import("@pages/Settings/PayrollSettings/SalarySettings/SalaryComponents/SalaryComponents")
        ),
        meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
      {
        path: SALARY_STRUCTURE,
        component: lazy(() => import("@pages/Settings/PayrollSettings/SalarySettings/SalaryStructure/SalaryStructure")),
        meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },

  // ......pay Schedule module
  {
    path: PAYROLL_SETUP,
    component: lazy(() => import("@pages/Settings/PayrollSettings/PayrollSetup/PayrollSetup")),
    meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: PAYROLL_SETUP,
        component: lazy(() => import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/PaySchedules")),
        meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },

  {
    path: PAY_SCHEDULE_FORM,
    component: lazy(
      () =>
        import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/PayScheduleFormLayout")
    ),
    // meta: scheduleAcl.parent,
    children: [
      { path: "*", redirect: "general" },
      {
        path: "general",
        component: lazy(
          () => import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/GeneralForm")
        ),
        // meta: [scheduleAcl.schedules.create, scheduleAcl.schedules.update],
        // isIndex: true,
      },

      {
        path: "attendance-policy",
        component: lazy(
          () =>
            import(
              "@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/AttendancePolicyForm"
            )
        ),
        // meta: [scheduleAcl.schedules.assignEmployees],
      },
    ],
  },
];
