export class Constant {
  public static readonly ACTIVE: string = "Active";
  public static readonly INACTIVE: string = "Inactive";
  public static readonly SUSPENDED: string = "Suspended";
  public static readonly PENDING: string = "Pending";
  public static readonly ARCHIVED: string = "Archived";
  public static readonly MONTH: string = "Month";
  public static readonly MONTHLY: string = "Monthly";
  public static readonly WEEK: string = "Week";
  public static readonly DAY: string = "Day";
  public static readonly WEEKLY: string = "Weekly";
  public static readonly WEEKEND: string = "Weekend";
  public static readonly EMPLOYEE = "Employee";
  public static readonly ADMIN = "Admin";
  public static readonly TEAM = "Team";
  public static readonly PAYABLE = "Payable";
  public static readonly LOGO_DEFAULT_EXTENSION: string[] = ["png", "jpg", "jpeg"];
  public static readonly NO_DATA = "--";
  public static readonly NOT_ASSIGNED = "N/A";

  public static readonly FIXED: string = "Fixed";

  public static readonly PAYABLE_TYPE_CASH = "CASH";
  public static readonly PAYABLE_TYPE_BANK = "BANK";
  public static readonly PAYABLE_TYPE_DIGITAL_BANKING = "DIGITAL_BANKING";
  public static readonly EMPLOYEE_CREATION_MANUAL_TYPE = "Manual";
  public static readonly EMPLOYEE_CREATION_AUTOMATED_TYPE = "Automated";

  public static readonly MALE = "Male";
  public static readonly FEMALE = "Female";

  public static readonly PROCESSING = "Processing";

  // for education results---
  public static readonly FIRST_CLASS = "1st Class";
  public static readonly SECOND_CLASS = "2nd Class";
  public static readonly THIRD_CLASS = "3rd Class";
  public static readonly OUT_OF_4 = "Out of 4";
  public static readonly OUT_OF_5 = "Out of 5";

  // for css styles---
  public static readonly DRAWER_WIDTH_FOR_EMPLOY_CARD = "w-[80%] md: w-[70%] lg:w-[550px]";
  public static readonly FULFILLED = "fulfilled";
  public static readonly DASHBOARD = "dashboard";
  public static readonly OFFLINE = "Offline";
  public static readonly ONLINE = "Online";

  public static readonly INITIAL_ONBOARD = {
    BASIC_INFO: "basic-info",
    STEP: "step",
    DIVISION: "division",
    DEPARTMENT: "department",
    GRADE: "grade",
    DESIGNATION: "designation",
    SESSION: "session",
  };

  public static readonly FORM_ELEMENTS = {
    HEADER: "header",
    SHORT_TEXT: "text",
    LONG_TEXT: "textarea",
    EMAIL: "email",
    CONTACT_NUMBER: "tel",
    DATE_PICKER: "date",
    DROPDOWN: "select",
    SINGLE_CHOICE: "radio",
    MULTIPLE_CHOICE: "checkbox",
    FILE_UPLOAD: "file",
  };

  public static readonly PAYROLL_ELEMENTS = {
    TAX_CODE: "TAX",
    BASIC_CODE: "BS",
    GROSS_CODE: "GS",
    FIXED_AMOUNT: "Fixed Amount",
    PERCENTAGE: "Percentage",
    FORMULA: "Formula",
    INCOME_TAX: "Income Tax",
    BASIC: "Basic",
    GROSS: "Gross",
    LATE: "Late",
    EARLY_EXIT: "Early Exit",
  };
}
